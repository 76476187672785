import React from 'react'
import { graphql } from 'gatsby'

import GDPR from '../components/Pages/GDPR'

const GDPRPage = ({ data, location }) => {
  const localeID = 'en_US'

  return <GDPR location={location} localeID={localeID} data={data} />
}

export default GDPRPage

export const pageQuery = graphql`
  query GDPRQueryEN {
    wpPage(slug: { eq: "gdpr" }, locale: { id: { eq: "en_US" } }) {
      title
      content
    }
  }
`
